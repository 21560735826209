import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageTypes, TrackProperty, TrackPropertyApiResponse } from '@rma/campaigns/private/domain-track-campaign';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiTrackPropertyStorageService {
  public readonly storageType = StorageTypes.Api;

  private trackedPropertyCodes: string[] = [];

  private readonly endpoints = {
    trackProperty: 'Users/Me/Track/Campaign/Code-{campaignCode}',
    untrackPropertyByTrackId: 'Users/Me/Track/Campaign/{trackId}',
    getTrackedPropertyCodes: 'Users/Me/Track/Campaigns',
  };

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly rmaUrls: RmaUrlsService,
  ) {}

  public trackProperty(campaignCode: string, payload: { firstName: string; email: string }): Observable<TrackProperty> {
    const url = this.rmaUrls.apiUrl(this.endpoints.trackProperty.replace('{campaignCode}', campaignCode));
    return this.httpClient
      .put<TrackPropertyApiResponse>(url, {
        FirstName: payload.firstName,
        EmailAddress: payload.email,
      })
      .pipe(
        map((response) => ({
          trackId: response.trackId,
          email: payload.email,
          trackPropertyCount: response.trackPropertyCount,
        })),
      );
  }

  public untrackPropertyByCampaignCode(campaignCode: string): Observable<void> {
    const url = this.rmaUrls.apiUrl(`Users/Me/Track/Campaign/Code-${campaignCode}`);
    return this.httpClient.delete<void>(url);
  }

  public untrackPropertyByTrackId(trackId: string): void {
    const url = this.rmaUrls.apiUrl(this.endpoints.untrackPropertyByTrackId.replace('{trackId}', trackId));
    this.httpClient.delete(url).subscribe();
  }

  public getTrackedPropertyCodes(forceReload: boolean): Observable<string[]> {
    if (this.trackedPropertyCodes && this.trackedPropertyCodes.length > 0 && !forceReload) {
      return of(this.trackedPropertyCodes);
    }
    const url = this.rmaUrls.apiUrl(this.endpoints.getTrackedPropertyCodes);

    return this.httpClient.get<string[]>(url);
  }
}
